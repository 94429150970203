import './scss/style.scss';
//import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap/js/dist/index';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tooltip';
import flatpickr from "flatpickr";
flatpickr.localize(flatpickr.l10ns[functions.getLang()]);
flatpickr.l10ns.default.firstDayOfWeek = 1;
import 'flatpickr/dist/l10n/hr.js';
import 'flatpickr/dist/l10n/de.js';

import 'lightgallery/src/js/lightgallery.js';
import 'lightgallery/modules/lg-thumbnail.js';

import 'select2';
import 'select2/dist/js/i18n/hr.js';

import lozad from 'lozad';

import functions from './js/functions.js';
import './js/globals.js';
import './js/loadGmap.js';
import './js/list.js';
import './js/details.js';
import './js/booking.js';
import './js/contact.js';
import './js/analytics';
//import './js/pagination.js';



window.observer = lozad('.lozad', {
    rootMargin: '200px 0px',
    loaded: el => {
        $(el).triggerHandler('lozadLoaded');
    }
});
window.observer.observe();