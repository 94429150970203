import functions from './functions.js';
import Slider from './Slider.js';
import inputPopover from './input-popover.js';
//import inputGuests from "./input-guests.js";

$(function() {

    let lastScrollTop = 0;
   
   $(window).on('scroll', e => {
       let st = window.pageYOffset || document.documentElement.scrollTop;
       if (st > lastScrollTop) {
            // downscroll code
            $('.main-header').addClass('hidden');
            $('.booking-sticky-container').addClass('top');
        } else {
            // upscroll code
            $('.main-header').removeClass('hidden');
            $('.booking-sticky-container').removeClass('top');
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
   });

    $('body').on('click', '.dropdown-menu', e => {
        e.stopPropagation();
    });

    inputPopover.apply();

    let heroSlider = new Slider('#hero-slider', {
        rubberband: false,
        loop: true,
        arrows: true,
        slidesPerView: 1,
        duration: 1200,
        autoplay: 4000,
        //autoplay: 0,
        pauseOnHover: true,
        pager: true,
    });

    let catalogueSliderCrads = [];
    let slidesPerView = 4;
    $('[id^=catalogue-slider-cards]').each((i, elem) => {
        const length = $(elem).find('.keen-slider__slide').length;
        if(length < 4) {
            $(elem).find('.keen-slider__slide').addClass('slider-card-width');
        } else {
            $('.keen-slider__arrows').removeClass('d-lg-none');
        }
        catalogueSliderCrads[i] = new Slider('#' + $(elem).attr('id'), {
            rubberband: false,
            loop: length > slidesPerView,
            controls: true,
            arrows: true,
            slidesPerView: slidesPerView,
            spacing: 30,
            duration: 1000,
            pager: true,
            autoplay: length > slidesPerView ? 3000 : 0,
            //autoplay: 0,
            pauseOnHover: true,
            breakpoints: {
                '(max-width: 1000px)': {
                    slidesPerView: 1,
                    loop: true,
                    spacing: 0,
                    controls: true,
                    arrows: true,
                    autoplay: 3000
                },
            },
        });
    });

    /* gallery */
    const lg = {};
    window.showGallery = (index, id) => {
        index = +index || 0;
        if ( lg[id] ) {
            lg[id].index = index;
            lg[id].build(index);
        } else {
            let $elem = $('[data-images-' + id + ']');
            lg[id] = $elem.lightGallery({
                dynamic: true,
                thumbnail: true,
                toogleThumb: false,
                thumbWidth: 130,
                thumbMargin: 10,
                download: false,
                preload: 2,
                index: index,
                dynamicEl: JSON.parse($elem.attr('data-images-' + id))
            }).data('lightGallery');
        }
    };
    $('[data-gallery-id]').on('click', function (e) {
        e.preventDefault();
        let galleryId = $(this).attr('data-gallery-id');
        galleryId && showGallery($(this).attr('data-index'), galleryId);
    });
    // don't focus div with gallery
    $('[data-gallery-id]').on('focus', function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
        return false;
    });
    /* end gallery */
    
    // menu links active
    const path = location.pathname.replace(/\/$/, '');
    $('.main-header [href], footer [href]').each((i, link) => {
        let href = link.getAttribute('href');
        if ( href && href != '#' ) {
            href = href.split('?')[0].replace(/\/$/, '');
            if ( path === href ) {
                $(link).addClass('active').closest('.nav-item').addClass('active');
            }
        }
    });

    // global tooltip init
    //('[data-toggle="tooltip"]').tooltip();

    // mobile menu handling
    (() => {
        const $header = $('.main-header');
        const $collapser = $('#main-menu');

        // ensure navbar toggler also open closes main-menu
        $collapser
            .on('show.bs.collapse', () => {
                document.body.classList.add('modal-transition');
                document.body.classList.add('modal-active');
            })
            .on('hide.bs.collapse', () => {
                document.body.classList.remove('modal-active');
            })
            .on('hidden.bs.collapse', () => {
                document.body.classList.remove('modal-transition');
            });

        // click on "overlay" closes menu
        $header.find('.header-overlay').on('click', () => {
            $collapser.collapse('hide');
        });
    })();

    // scrool to element handling
    $(document).on('click', '[data-scroll-to]', e => {
        e.preventDefault();
        const $this = $(e.target);
        let scrollTo = $this.attr('data-scroll-to').split(',');
        functions.scrollToElement(scrollTo[0], scrollTo[1] || 0);
    });
    
    // global datepicker init with automatic "dateTo" set if provided
    let displayDate = new Date('2019-06-01');
    let now = functions.cleanDate(new Date);
    if ( now > displayDate ) {
        displayDate = now;
    }

    $('.datepicker').each((i, elem) => {
        const $datepicker = $(elem);
        const minDate = $datepicker.attr('data-min');
        const maxDate = $datepicker.attr('data-max');
        $datepicker.flatpickr({
            altInput: true,
            wrap: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            minDate: minDate ? minDate : now,
            maxDate: maxDate ? maxDate : null,
            disableMobile: true,
            prevArrow: '<i class="la la-angle-left pr-2"></i>',
            nextArrow: '<i class="la la-angle-right pl-2"></i>',
            onChange(selectedDates, dateStr, instance) {

                const $first = $(instance.input);
                // prevent other logic if second datepicker is triggered by first
                if (instance._openByFirst) {
                    delete instance._openByFirst;
                    return;
                }
                
                if ($first.attr('data-firstDate')) {
                    const $second = $first.closest('form').find('[data-secondDate]').closest('.datepicker');
                    if ($second.length) {

                        var firstDate = new Date(selectedDates[0]);
                        firstDate.setDate(firstDate.getDate() + 1);
                        
                        var secondInstance = $second[0]._flatpickr;
                        // check if firstDate is invalid
                        if (firstDate.getTime() === firstDate.getTime()) {
                            secondInstance._openByFirst = true;
                            secondInstance.set('minDate', firstDate);
                            if (!secondInstance.selectedDates[0]) {
                                firstDate.setDate(firstDate.getDate() + 6);
                                secondInstance.setDate(firstDate);
                            }
                            secondInstance.open();
                            $(secondInstance.altInput).focus();
                        }
                        else if (secondInstance.selectedDates[0]) {
                            secondInstance.clear();
                        }
                    }
                }
                // check if second date is cleared
                else if ($first.attr('data-secondDate') && !selectedDates[0]) {
                    $first.closest('form').find('[data-firstDate]').closest('.datepicker')[0]._flatpickr.clear();
                }
            },
            onOpen(selectedDates, dateStr, instance) {
                if (instance.input.getAttribute('data-secondDate')) {
                    const $first = $(instance.input).closest('form').find('[data-firstDate]').closest('.datepicker');
                    if ($first.length) {
                        const firstInstance = $first[0]._flatpickr;
                        if (!firstInstance.input.value) {
                            instance.close();
                            firstInstance.open();
                            firstInstance.altInput.focus();
                            return;
                        }
                    }
                }
                !dateStr && instance.jumpToDate(minDate ? minDate : displayDate);
            }
        });
        // to style clear toggler
        $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
    }); 

    $('.datepicker-range').each((i, elem) => {
        const $datepicker = $(elem);
        const minDate = $datepicker.attr('data-min');
        const maxDate = $datepicker.attr('data-max');
        const arrival = $datepicker.attr('data-arrival') ? $datepicker.attr('data-arrival') : [];
        const defaultDate = $datepicker.attr('data-defaultDate') ? $datepicker.attr('data-defaultDate') : [];
        const duration = Number($datepicker.attr('data-duration'));
        $datepicker.flatpickr({
            mode: "range",
            altInput: true,
            wrap: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            defaultDate: defaultDate,
            minDate: minDate && minDate >= now ? minDate : now,
            maxDate: maxDate ? maxDate : null,
            disableMobile: true,
            prevArrow: '<i class="la la-chevron-circle-left la-fw la-2x pr-2"></i>',
            nextArrow: '<i class="la la-chevron-circle-right la-fw la-2x pl-2"></i>',
            locale: {
                rangeSeparator: ' - '
            },
            onDayCreate(dObj, dStr, fp, dayElem){

                let to = new Date(maxDate);
                let weekday =  new Date(dayElem.dateObj).getDay();
                weekday = '' + (weekday ? weekday : 7);
                to.setHours(0, 0, 0, 0);

                if ( dayElem.dateObj > to || (arrival.length && $.inArray( weekday , arrival ) == -1) ) {
                    dayElem.classList.add('unselectable');
                }
            },
            onChange(selectedDates, dateStr, instance) {

                /*const picker = $datepicker[0]._flatpickr;

                if(selectedDates.length < 2) {
                    const firstDate = new Date(selectedDates[0]);
                    const secondDate = new Date(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate() + duration);

                    //instance.set('maxDate', secondDate);
    
                    picker.setDate([firstDate, secondDate], false);

                    instance.close();
                }*/

            },
        });
        // to style clear toggler
        $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
    });

    // select2 templates can be defined as data-template="destinations" ...
    const selectTemplates = {
        destinations: data => {
            if ( !data.element ) {
                return data.text;
            }
            /*if(data.placeId && data.placeName) {
                //const $element = $(data.element).addClass(data.element.className).text(data.placeName);
                const $element = $('<span>').addClass(data.element.className).text(data.placeName);
                return $element;
            }*/
            if(data.text) {
                const $element = $('<span>').addClass(data.element.className).text(data.text);
                /*let elemClass;
                switch ( (data.id).split(':').length ) {
                    //case 1: elemClass = 'pl-4'; break;
                    case 2: elemClass = 'pl-2 text-warning'; break;
                    case 3: elemClass = 'pl-2 text-warning'; break;
                    case 4: elemClass = 'pl-3'; break;
                    default: elemClass = 'h5'; break;
                }
                $element.addClass(elemClass);*/
                return $element;
            }
        },
    };

    // global init select2
    // data-templates
    // data-data
    // data-allow-clear
    // data-nosearch
    // placeholder
    $.map($('.select2'), elem => {
        const $elem = $(elem);
        const config = { dropdownParent: $elem.parent(), language: functions.getLang() };
        config.allowClear = $elem.attr('data-allow-clear');

        // find select2 which has an attribute data-data
        const data = $elem.attr('data-data');
        if ( data ) {
            try {
                config.data = JSON.parse(data);
            } catch (e) {}
        } 
        else if ( $elem.attr('data-special') ) {
            let newData = $('body').attr('data-select-' + $elem.attr('data-special'));
            config.data = JSON.parse(newData);
        }

        if ( $elem.attr('data-template') ) {
            config.templateResult = selectTemplates[$elem.attr('data-template')];
        }
        if ( $elem.attr('data-template-selection') ) {
            config.templateSelection = selectTemplates[$elem.attr('data-template-selection')];
        }

        if ( $elem.attr('data-nosearch') !== undefined ) {
            config.minimumResultsForSearch = Infinity;
        }

        let placeholder = $elem.attr('placeholder');
        $elem.removeAttr('placeholder');
        config.placeholder = placeholder || ' ';
        
        $elem.select2(config)
            .on('select2:clear', () => {
                $elem[0].cleared = true;
            })
            .on('select2:opening', e => {
                if ( $elem[0].cleared ) {
                    e.preventDefault();
                    $elem[0].cleared = false;
                }
            });
    });
    
    // advanced form controls
    // input-popover
    $('body').on('click', '.dropdown-menu-popover', e => {
        e.stopPropagation();
    });
    //inputPopover.apply();
    //inputGuests.apply();

    // button checkbox
    /*$('.btn-checkbox').on('click', e => {
        let input = $(e.target).prev()[0];
        input.checked = !input.checked;
    });*/

    $('#cookie-bar-accept').on('click', function (e) {
        functions.setCookie('cookie_usage', 'accepted');
        $("#cookie-bar").addClass('d-none');
    });

    /*$('.currency').on('click', function (e) {
        functions.setCookie('currency', $(this).data('currency'));
        location.reload();
    });*/

    $('.currency-change').on('click', function (e) {
        functions.setCookie('currencyIdTo', e.target.id);
        location.reload();
    });

    const $window = $(window);

    // google map
    const $map = $('.od-map');
    if ($map.length) {
        let map;
        let mapTimeout;
        // check if in viewport
        if ($map[0].getBoundingClientRect) {
            $window.on('load, scroll', e => {
                if (map) {
                    return;
                }
                clearTimeout(mapTimeout);
                mapTimeout = setTimeout(() => {
                    let screenHeight = document.documentElement.clientHeight;
                    let mapBounds = $map[0].getBoundingClientRect();
                    if (screenHeight > mapBounds.top && mapBounds.bottom > 0) {
                        loadGoogleMaps();
                    }
                }, 1000);
            });
        } else {
            $window.on('load', e => loadGoogleMaps);
        }
        // map init
        $window.on('gMapsLoaded', e => {
            let center;
            let meetingPoints;
            let isMarker;
            try {
                center = JSON.parse($map.attr('data-lat-lng'));
                meetingPoints = JSON.parse($map.attr('data-meetingpoints'));
                isMarker = JSON.parse($map.attr('data-marker'));
            } catch (e) { }

            if (center) {
                map = new google.maps.Map($map[0], {
                    center: center,
                    zoom: 15
                });
                if (isMarker) {
                    meetingPoints.forEach(meetingPoint => {
                        //console.log(meetingPoint);
                        new google.maps.Marker({
                            map: map,
                            position: new google.maps.LatLng(meetingPoint.lat, meetingPoint.lng),
                            label: meetingPoint.title_en
                        });
                    });
                } else {
                    new google.maps.Circle({
                        map: map,
                        center: center,
                        radius: 600, // 10 miles in metres
                        fillColor: '#389589',
                        strokeOpacity: 0.8
                    });
                }
            }
        });
    }

    const heroVideo = document.getElementById("hero-video");
    if(heroVideo) {
        const observer = new IntersectionObserver(function (intersection) {
            if ( intersection[0].isIntersecting ) {
                if ( heroVideo.getAttribute('data-loaded') ) {
                    heroVideo.play();
                }
            } else {
                heroVideo.pause();
            }
        }, { threshold: 0.5 });
        observer.observe(heroVideo);
        $(heroVideo).on('lozadLoaded', e => heroVideo.pause());
    }
 
});