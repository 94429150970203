$(function () {
    let $tracking = $('.gatracking');

    if ($tracking.length)
    {
        let trackingData = $('[data-gatracking]').length ? JSON.parse($('[data-gatracking]').attr('data-gatracking')) : null;
        let conversionData = $('[data-gaconversion]').length ? JSON.parse($('[data-gaconversion]').attr('data-gaconversion')) : null;

        if (trackingData)
        {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push(trackingData);
        }
        if(conversionData) {
            gtag(conversionData);
        }
    }
});