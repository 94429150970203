import KeenSlider from 'keen-slider';

class Slider {
	constructor(selector, origOpts = {}) {
		
		this.selector = selector;
		this.$element = $(selector);
		this.$slides = this.$element.find('.keen-slider__slide');
		this.$pager = this.$element.next('.keen-slider__pager');
		this.$arrows = this.$element.next('.keen-slider__arrows');
		
		this.origOpts = origOpts;
		if ( this.origOpts.fade ) {
			this.origOpts.slides = this.$slides.length;
			this.$element.addClass('keen-slider__fade');
		}
		this.opts = Object.assign({}, origOpts);
		this._autoplay = {
			hoverPause: false,
			dragPause: false
		};
		this.opts.created = () => {
			this.opts.autoplay && this.registerAutoplay();
			this.opts.arrows && this.registerArrows();
			this.opts.pager && this.registerPager();
			this.origOpts.created && this.origOpts.created();
			this.$element.addClass('keen-slider__created');
			this.$pager.addClass('keen-slider__created');
		};
		this.opts.dragStart = () => {
			this._autoplay.dragPause = true;
			this._tryAutoplay();
			this.origOpts.dragStart && this.origOpts.dragStart();
		};
		this.opts.dragEnd = () => {
			this._autoplay.dragPause = false;
			this._tryAutoplay();
			this.origOpts.dragEnd && this.origOpts.dragEnd();
		};
		this.opts.slideChanged = () => {
			setTimeout(() => {
				let activeIndex = this.instance.details().relativeSlide || 0;
				this.$slides.each((index, slide) => {
					let $slide = $(slide);
					$slide.toggleClass('active', index == activeIndex);
					// auto ajax load prev & next slides if applicable
					if ( index == activeIndex ) {
						$slide.next().add($slide.prev()).each(function () {
							let $slide = $(this);
							let bg = $slide.attr('data-background-image');
							bg && $slide.css('background-image', `url("${bg}")`);
						});
					}
				});
				this.$pager.length && this.$pager.find('button').each((index, btn) => {
					$(btn).toggleClass('active', index == activeIndex);
				});
				this._tryAutoplay();
				this.origOpts.slideChanged && this.origOpts.slideChanged();
			});
		};
		this.instance = new KeenSlider(this.$element[0], this.opts);
	}
	registerArrows() {
		this.$arrows.on('click', '[data-dir]', e => {
			e.preventDefault();
			this.instance[e.currentTarget.getAttribute('data-dir')]();
		});
	}
	registerPager() {
		if ( this.$pager.length ) {
			this.$pager.find('.keen-slider__pager-buttons').html($.map(this.$slides, function (slide, i) {
				return `<button type="button" data-slide="${i}"></button>`;
			}).join(''));
			this.$element.addClass('keen-slider__hasPager');
			this.$pager.on('click', 'button', e => {
				e.preventDefault();
				this.instance.moveToSlideRelative(e.currentTarget.getAttribute('data-slide'));
			});
		}
		
	}
	registerAutoplay() {
		if ( this.opts.pauseOnHover ) {
			this.$element.on('mouseenter', () => {
				this._autoplay.hoverPause = true;
				this._tryAutoplay();
			});
			this.$element.on('mouseleave', () => {
				this._autoplay.hoverPause = false;
				this._tryAutoplay();
			});
		}
		this._tryAutoplay();
	}
	_tryAutoplay() {
		if ( this.opts.autoplay ) {
			clearTimeout(this._autoplay.timeout);
			this._autoplay.timeout = setTimeout(() => {
				if ( !(this._autoplay.dragPause || this._autoplay.hoverPause) ) {
					this.instance.next();
				}
			}, this.opts.autoplay);
		}
	}
}

export default Slider;