import functions from './functions.js';
import Slider from "./Slider";
import calendar from  './calendar.js';
import {SimpleGuests, SimpleGuestsDefaults} from "./simple-guests.js";

SimpleGuestsDefaults.adultsLabel = window.adultsLabel || SimpleGuestsDefaults.adultsLabel;
SimpleGuestsDefaults.childrenLabel = window.childrenLabel || SimpleGuestsDefaults.childrenLabel;
SimpleGuestsDefaults.childrenText = window.childrenText || SimpleGuestsDefaults.childrenText;
SimpleGuestsDefaults.infantsLabel = window.infantsLabel || SimpleGuestsDefaults.infantsLabel;
SimpleGuestsDefaults.guestsText = window.guestsText || SimpleGuestsDefaults.guestsText;
SimpleGuestsDefaults.infantsText = window.infantsText || SimpleGuestsDefaults.infantsText;
SimpleGuestsDefaults.applyText = window.applyText || SimpleGuestsDefaults.applyText;

$(() => {
    details.init();
});

const details = {
    $form: null,
    form: null,
    params: {
        unitId: null, 
        guests: '1,0,0', 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
    },
    init() {

        if(! $('.page-type-details')[0]) {
            return;
        }

        let detailsSlider = new Slider('#details-slider', {
            rubberband: false,
            loop: true,
            arrows: true,
            slidesPerView: 1,
            duration: 1000,
            //autoplay: 3000,
            autoplay: 0,
            pauseOnHover: true,
            pager: true,
            breakpoints: {
                '(max-width: 1000px)': {
                    slidesPerView: 1,
                },
            },
        });

        let itinerarylider = [];
        $('[id^=itinerary-slider]').each((i, elem) => {
            itinerarylider[i] = new Slider('#' + $(elem).attr('id'), {
                rubberband: false,
                loop: true,
                arrows: true,
                slidesPerView: 1,
                spacing: 0,
                duration: 1000,
                autoplay: 0,
                pauseOnHover: true,
                pager: false,
            });
        });

        details.getParams();

        details.params.unitId = $('[data-unitid]').attr('data-unitid');
        details.params.objectGroupId = $('[data-objectgroupid]').attr('data-objectgroupid');

        details.$form = $('form');
        details.form = details.$form[0];

        if($('[data-calendarconfig]')[0]) {
            details.calendarInit();
        }

        functions.setFormData(details.form, details.params);

        $('.simple-guests').each((i, elem) => {
            const simpleGuests = new SimpleGuests(elem);
            simpleGuests.setValue(details.params.guests);
        });

        $('[id^=paymentModel]').each((i, item) => {
            if($(item).is(':checked')) {
                details.params.paymentModelId = $(item).val();
            }
        });

        details.getCalculation();

        details.loadReviews();

        $('[name="dates"]').on('change', e => {
            e.preventDefault();
            const $target = $(e.target);
            details.params.dates = $target.val();
            details.splitCalendarDates($target.val());
            functions.setUrlData(details.params, true);
        });

        $('[name="guests"]').on('change', e => {
            e.preventDefault();
            const $target = $(e.target);
            details.params.guests = $target.val();
            functions.setUrlData(details.params, true);
        });

        $('#bookingModal').on('shown.bs.modal', e => {
            details.$form = $('.modal-content form');
            details.form = details.$form[0];
            details.getParams();
            functions.setFormData(details.form, details.params);
            details.getCalculation();
        });

        $('[id^=paymentModel]').on('change', e => {
            const $target = $(e.target);
            details.params.paymentModelId = $target.val();
            $('#paymentModel' + details.params.paymentModelId).attr('checked', true);
            functions.setUrlData(details.params, true);
        });

        $(window).on('hashchange', e => {
            details.getParams();

            if(details.params.dates) {
                let dates = $('[data-calendarconfig][data-mode]').attr('data-mode') == 'range' 
                    ? details.params.dates.split(' - ') 
                    : details.params.dates;
                $('.datepicker-range-book')[0]._flatpickr.setDate(dates);
            }

            functions.setFormData(details.form, details.params);
            details.getCalculation();
        });
    },
    loadReviews() {
        $('.reviews-more').on('click', function (e) {
            let $button = $(e.currentTarget);
            let perPage = 3;
            let page = +$button.data('page') + 1;
            let total = Math.round(+$button.data('total') / perPage);

            $.post('/services/reviews/', { language : functions.getLang(), perPage : perPage, page: page, objectId: $button.data('objectid') }).then(response => {
                if (response.status == true) {
                    $button.data('page', page);
                    if (total <= page) {
                        $button.attr('disabled', 'disabled');
                    }
                    $(".reviews").append(response.html);
                }
            });
        });
    },
    calendarInit() {
        let config = JSON.parse($('[data-calendarconfig]').attr('data-calendarconfig'));
        let mode = $('[data-calendarconfig][data-mode]').attr('data-mode');

        let defaultDate = $('[data-calendarconfig][data-defaultdate]').attr('data-defaultdate');
        defaultDate = details.params.dates ? details.params.dates : defaultDate;
        if(defaultDate) {
            let dates = defaultDate.split(' - ');
            details.params.dates = defaultDate;
            details.splitCalendarDates(details.params.dates);
            defaultDate = dates[1] ? [dates[0], dates[1]] : (dates[0] ? dates[0] : []);
        }

        calendar.calendar.init(
            $('.datepicker-range-book[name="dates"]'),
            {
                availabilityMask : config.availabilityMask,
                periods : config.periods,
                translations : config.translations,
                defaultDate: defaultDate ? defaultDate : [],
                mode: mode,
                inline: true,
                containerClass : 'details-booking-calendar'
            },
            details.getCalculation
        );

        calendar.calendar.init(
            $('.datepicker-book-small'),
            {
                availabilityMask : config.availabilityMask,
                periods : config.periods,
                translations : config.translations,
                defaultDate: defaultDate ? defaultDate : [],
                mode: mode,
                showMonths: 1,
                inline: false,
                wrap: true,
                containerClass : 'details-booking-calendar-small'
            },
            details.getCalculation
        );
    },
    calendarClear() {
        $('.datepicker-range-book[name="dates"]').flatpickr().clear();
        details.params.dates = null;
        $('.unit-dates').text('--');
        $('.unit-price-total').text('--');
        $('.unit-price-total-converted').text('--');
    },
    splitCalendarDates(dates) {
        if(dates || dates == '') {
            const aDates = dates.split(' - ');
            details.params.dateFrom = aDates[0] ? aDates[0] : null;
            details.params.dateTo = aDates[1] ? aDates[1] : aDates[0];
        }
    },
    getParams() {
        details.params = $.extend({}, details.params, functions.getUrlData(true));
        details.splitCalendarDates(details.params.dates);
    },
    isBookable(data) {
        return data.isAvailable && data.calc.total && data.calc.status == 'Active';
    },
    /*getCalcErrorMessage(error) {
        if (error && details.calcErrorMessages[error.type]) {
            return details.calcErrorMessages[error.type].replace('{$condition}', error.condition);
        }
        return details.calcErrorMessages.general;
    },*/
    getCalculation() {
        
        if(
            details.params.unitId
            && details.params.guests 
            && details.params.dateFrom 
            && details.params.dateTo 
            && details.params.objectGroupId
        ) {

            return $.getJSON(
                location.protocol
                + '//'
                + location.hostname
                + '/services/calc/'
                + '?unitId=' + details.params.unitId
                + '&guests=' + details.params.guests
                + '&dateFrom=' + details.params.dateFrom
                + '&dateTo=' + details.params.dateTo
                + '&objectGroupId=' + details.params.objectGroupId
                + '&language=' + functions.getLang()
            )
            .done(response => {

                if(response.status) {
                    const data = response.data || [];
                    const symbol = $('.unit-price-total-converted').attr("data-symbol");

                    //console.log(data);

                    Object.entries(data).forEach(unit => {
                        const unitId = unit[0];
                        const unitData = unit[1];

                        const $btnBooking = $('.btn-booking');
                        //console.log(details.isBookable(unitData));

                        if(details.isBookable(unitData)) {
                            const unitCalc = unitData.calc;
                            const unitCalcGuests = unitData.calc.guests;

                            let guests = 0;
                            let adults = 0;
                            let kids = 0;
                            unitCalcGuests.split(':').map(value => {
                                value = value.split(',');
                                adults = value[0];
                                kids = value[1];
                                guests += (+value[0] + +value[1]);
                            });

                            $('[id^=paymentModel]').each((i, item) => {
                                const modelId = $(item).val();

                                let modelPercent = $(item).attr('data-advancepaymentpercent');
                                modelPercent = modelPercent ? modelPercent/100 : null;

                                let advancePaymentAmount = $(item).attr('data-advancepaymentamount') ? $(item).attr('data-advancepaymentamount') * guests : null;
                                let advancePaymentAmountConverted = $(item).attr('data-advancepaymentamountconverted') ? $(item).attr('data-advancepaymentamountconverted') * guests : null;

                                let advanceAmount = modelPercent ? unitCalc.total * modelPercent : advancePaymentAmount;
                                let advanceAmountConverted = modelPercent ? unitCalc.totalEUR * modelPercent : advancePaymentAmountConverted;

                                advanceAmount = functions.formatMoney(advanceAmount, 2, '.', ',');
                                advanceAmountConverted = functions.formatMoney(advanceAmountConverted, 2, '.', ',');

                                $('.advance-amount[data-paymentmodelid=' + modelId + ']').text(unitCalc.currencySymbol + ' ' + advanceAmount);
                                $('.advance-amount-converted[data-paymentmodelid=' + modelId + ']').text(advanceAmountConverted + ' ' + symbol);
                            });

                            const total = unitCalc.currencySymbol + ' ' + functions.formatMoney(unitCalc.total, 0, '.', ',');
                            const totalEUR = functions.formatMoney(unitCalc.totalEUR, 2, '.', ',');

                            const calcItems = unitCalc.items;
                            let priceOld = 0.00;
                            let discountTitle = null;

                            calcItems.forEach(item => {
                                // popust za djecu ne ulazi u prikaz discount cijene
                                if((item.itemType != 'discount' || item.itemDefinitionId == 129) && !item.optional && !item.onSpot) {
                                    priceOld += item.itemTotal;
                                }
                                if(item.itemType == 'discount') {
                                    discountTitle = item.itemTitle;
                                    if(item.data && item.data.text) {
                                        discountTitle = item.data.text;
                                    }
                                }
                            });

                            $btnBooking.removeClass('disabled');
                            //$btnBooking.find('[data-book]').toggleClass('d-none', unitData.booking.status != 'Reservation');
                            //$btnBooking.find('[data-inquiry]').toggleClass('d-none', unitData.booking.status == 'Reservation');
                            $('.unit-price-total').text(total);
                            $('.unit-price-total-converted').text(totalEUR + " " + symbol);

                            $('.unit-price-total').removeClass('d-none');
                            $('.unit-total-price').removeClass('d-none');

                            if(unitCalc.total != priceOld) {
                                $('.unit-price-old').text(unitCalc.currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ','));
                                $('.unit-total-old').removeClass('d-none');
                            }

                            $('.unit-price-container').removeClass('d-none');

                            //$('.unit-dates').text(functions.formatDate(details.params.dateFrom, 'dd.mm.') + ' - ' + functions.formatDate(details.params.dateTo, 'dd.mm.yyyy.'));

                            if($btnBooking.attr('href')) {
                                $btnBooking.attr('href',
                                    $btnBooking.attr('href').split('?')[0] +
                                    '?unitId=' + unitId +
                                    '&objectGroupId=' + details.params.objectGroupId +
                                    '&dateFrom=' + details.params.dateFrom +
                                    '&dateTo=' + details.params.dateTo +
                                    '&guests=' + details.params.guests +
                                    '&paymentModelId=' + details.params.paymentModelId
                                );
                            }
                        } else {
                            $btnBooking.addClass('disabled');
                            $('.unit-price-container').addClass('d-none');
                            $('.unit-price-total').addClass('d-none');
                            $('.unit-price-total-converted').addClass('d-none');
                            $('.unit-total-price').addClass('d-none');
                        }

                    });
                }

            }).fail(function (error) {
                console.log(error);
            });
        }

        else {
            $('.btn-booking').addClass('disabled');
        }
    }
}