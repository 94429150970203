import flatpickr from "flatpickr";
import functions from "./functions.js";
const dNone = 'd-none';
//const language = functions.getLang() == 'bs' ? 'hr' : functions.getLang();
const language = functions.getLang();
const monthsLong = flatpickr.l10ns[language].months.longhand;
const monthsShort = flatpickr.l10ns[language].months.shorthand;
const curDate = new Date;
const curMonth = curDate.getMonth();
let curYear = curDate.getFullYear();
let newYear = curYear;

const extractMonth = short => {
    let index = monthsShort.indexOf(short);
    let year = curYear;
    let month = index + 1;
    month = month > 9 ? month : '0' + month;
    // add year when we pass 12    
    if ( newYear == curYear && index == monthsShort.length - 1 ) {
        curYear++;
        newYear++;
    }
    return {
        value: year + '-' + month + '-01',
        year: year,
        short: short,
        long: monthsLong[index]
    };
};
const buildMonths = () => {
    return months.map(month => {
        return `
            <div class="col-3 mt-2">
                <button class="btn btn-light btn-sm btn-block py-2 text-uppercase" data-value="${month.value}">
                    ${month.short}<div class="small mt-n1">${month.year}</div>
                </button>
            </div>
        `;
    }).join('');
};

/*
    Populate months
    1. rest of this year
    2. whole new year
    3. what is left till we reach 20 months
*/

let months = monthsShort.slice(curMonth).map(extractMonth).concat(monthsShort.map(extractMonth));
let moreMonths = 20 - months.length;
moreMonths > 0 && (months = months.concat(monthsShort.slice(0, moreMonths).map(extractMonth)));
moreMonths < 0 && (months = months.slice(0, 20));

const init = elem => {
    const instance = {
        elem: elem,
        $elem: $(elem)
    };
   
    instance.$value = instance.$elem.find('.input-popover-value');
    instance.$dropdown = instance.$elem.closest('.dropdown');
    instance.$menu = instance.$dropdown.find('.dropdown-menu-popover');
    instance.$hidden = instance.$dropdown.find('input[type="hidden"]');
    instance.$removeValue = instance.$elem.find('.remove-popover-value');
    !instance.value && instance.$removeValue.addClass('d-none');
    instance.placeholder = instance.$elem.attr('placeholder');

    // populate dropdown
    instance.$menu.html(`<div class="form-row">${buildMonths()}</div>`);

    // events
    // open
    instance.$dropdown.on('show.bs.dropdown', e => {
        instance.$menu.find('.active').removeClass('active');
        instance.$menu.find('.btn[data-value="' + instance.value + '"]').addClass('active');
    });

    instance.$removeValue.on('click', e => {
        e.stopPropagation();
        // hide x btn
        $(e.currentTarget).addClass('d-none');
        // "unselect" the month
        instance.$menu.find('.btn[data-value="' + instance.value + '"]').removeClass('active');
        // actually remove value
        instance.$hidden[0].value = "";
        elemChange(instance);
    });
    
    // button click element and trigger change
    instance.$menu.on('click', 'button', e => {
        e.preventDefault();
        instance.$hidden[0].value = $(e.currentTarget).attr('data-value');
        instance.$hidden.trigger('change');
        instance.$elem.dropdown('hide');
    });
    // elem change
    instance.$hidden.on('change', e => elemChange(instance));    
    // init set
    elemChange(instance);
};

const elemChange = instance => {
    instance.value = instance.$hidden[0].value || null;
    instance.value && instance.$removeValue.removeClass('d-none');
    let selectedMonth = months.find(month => {
        return month.value == instance.value;
    });
    instance.$value.text(selectedMonth ? selectedMonth.long + ' ' + selectedMonth.year : (instance.placeholder || ''));
};

export default {
    init: init,
    apply() {
        $('.input-popover').each((i, elem) => {
            init(elem);
        });
    }
};