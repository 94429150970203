window.gMapsLoaded = false;
window.gMapsCallback = function () {
    window.gMapsLoaded = true;
    $(window).trigger('gMapsLoaded');
    window.gMapDef.resolve();
};
window.loadGoogleMaps = function () {
    if ( window.gMapsLoaded ) {
        window.gMapsCallback();
        return window.gMapDef.promise();
    }
    if ( !window.gMapDef ) {
        window.gMapDef = $.Deferred();
        var script_tag = document.createElement('script');
        script_tag.setAttribute("type","text/javascript");
        script_tag.setAttribute("src","https://maps.google.com/maps/api/js?key=AIzaSyB6qRTvVD49z9kByElBGfN3ZCfEUk_8tF0&callback=gMapsCallback");
        (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);
    }
    return window.gMapDef.promise();
};