import functions from "./functions.js";

$(function () {

    const $form = $('.booking-form');
    const form = $form[0];

    if (!form) {
        return;
    }

    const booking = {
        $form: null,
        $payment: null,
        $calculation: null,
        calc: [],
        persons: null,

        init() {
            booking.$form = $(".booking-form");
            booking.$calculation = $(".table-calculation");
            booking.$payment = $(".payment-details");
            booking.$calculationFlight = booking.$calculation.find("*[data-calcFlight]");
            booking.params = {};


            booking.$calculation.find("*[data-calc]").each(function (index) {
                let item = JSON.parse($(this).attr('data-calc'));
                item.inCalc = item.isOptional ? 0 : 1;
                booking.calc.push(item);
            });

            booking.$calculation.find('input[type="checkbox"]').change(function (e) {
                let $row = $(this).closest('tr');
                let $quantity = $row.find('select.quantity');
                let value = +$quantity.val();
                let checked = $(this).is(':checked') ? 1 : 0;

                if (checked && !value) {
                    $quantity.prop('value', 1);
                } else if (!checked && value) {
                    $quantity.prop('value', 0);
                }

                booking.calculate($(this));
            });



            booking.$calculation.find('select.quantity').change(function (e) {
                let $row = $(this).closest('tr');
                let $checkbox = $row.find('input[type="checkbox"]');
                let value = +$(this).val();

                if (value && !$checkbox.is(':checked')) {
                    $checkbox.prop('checked', true);
                } else if (!value && $checkbox.is(':checked')) {
                    $checkbox.prop('checked', false);
                }

                booking.calculate($(this));
            });

            booking.total();


            if (booking.$payment.length) {
                booking.params.paymentType = 'creditCard';
                booking.$payment.find('input[name="paymentType"]').change(function (e) {
                    booking.params.paymentType = booking.$payment.find('input[name="paymentType"]:checked').val();
                });
            }

            booking.$form.on('submit', e => {
               booking.submit();
            });

        },
        submit() {

            $.each(booking.calc, function(key, item) {
                if ( ! item.inCalc) {
                    delete booking.calc[key];
                } else if (item.itemDefinitionId.categoryId.key == 'baseRate') {
                    let $guestsPerProduct = $("[data-guests-parentId='" + item.parentId + "']");
                    let guests = [];

                    if ($guestsPerProduct.length) {
                        $guestsPerProduct.find('[data-guests]').each(function (index) {
                            let g = +$(this).data('guests');
                            guests[index] = {
                                firstName : $(this).find("input[name='firstName" + g + "']").val(),
                                lastName : $(this).find("input[name='lastName" + g + "']").val(),
                                age : $(this).find("input[name='age" + g + "']").val()
                            };
                        });
                        booking.calc[key].guestsData = guests;
                    }
                }
            });

            booking.params.calc = JSON.stringify(booking.calc);
            booking.params.requestId = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);

            $.each(booking.params, function (key, param) {
                $('<input>').attr({
                    type: 'hidden',
                    name: key,
                    value: param
                }).appendTo(booking.$form);
            });

        },
        calculate($elem) {
            let $row = $elem.closest('tr');

            let $checkbox = $row.find('input[type="checkbox"]');
            let checked = $checkbox.is(':checked') ? 1 : 0;
            let id = $checkbox.attr('id');

            let $quantity = $row.find('select.quantity');
            let quantity = null;

            if ($quantity.length) {
                quantity = +$quantity.val();
            }


            $.each(booking.calc, function (key, calc) {
                if (calc.id == id) {

                    if (quantity == null) {
                        quantity = booking.calc[key].quantity;
                    }

                    booking.calc[key].inCalc = checked;
                    booking.calc[key].quantity = (quantity || 1);
                    booking.calc[key].total = booking.itemTotal(booking.calc[key]);

                    $row.find('.item-total-amount').html(functions.formatMoney(booking.calc[key].total, 2, ',', '.'));
                }
            });

            booking.total();
        },
        itemTotal(item) {
            let itemTotal = 0.00;

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek']) > -1) {
                    itemTotal = +item.quantity * +item.price;
                } else {
                    itemTotal = +item.price;
                }
            } else {
                itemTotal = +item.quantity * +item.price;
            }

            return itemTotal;
        },
        itemTotalConverted(item) {
            let itemTotal = 0.00;

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek']) > -1) {
                    itemTotal = +item.quantity * +item.priceConverted;
                } else {
                    itemTotal = +item.priceConverted;
                }
            } else {
                itemTotal = +item.quantity * +item.priceConverted;
            }

            return itemTotal;
        },
        total() {
            let total = 0.00;
            let totalAmountConverted = 0.00;

            $.each(booking.calc, function (key, calc) {
                if (!calc.onSpot && calc.inCalc) {
                    total += booking.itemTotal(calc);
                    totalAmountConverted += booking.itemTotalConverted(calc);
                }
            });

            booking.$calculation.find('.total-amount').html(functions.formatMoney(total, 2, ',', '.'));

            $('.total-amount').html(functions.formatMoney(total, 2, ',', '.'));
            $('.total-amount-converted').html(functions.formatMoney(totalAmountConverted, 2, ',', '.'));

            const advancePaymentPercent = $('[data-advancePaymentPercent]').attr('data-advancepaymentpercent');
            const advance = total * (advancePaymentPercent / 100);
            const rest = total - advance;

            const advanceConverted = totalAmountConverted * (advancePaymentPercent / 100);
            const restConverted = totalAmountConverted - advanceConverted;

            $('.payment-details .advance').html(functions.formatMoney(advance, 2, ',', '.'));
            $('.payment-details .rest').html(functions.formatMoney(rest, 2, ',', '.'));

            $('.payment-details .advance-converted').html(functions.formatMoney(advanceConverted, 2, ',', '.'));
            $('.payment-details .rest-converted').html(functions.formatMoney(restConverted, 2, ',', '.'));
        }
    }

    booking.init();
});